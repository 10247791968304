import { Box } from '@mui/material';
import { useRouter } from 'next/router';
import { gql, useQuery } from '@apollo/client';

export default function Banner() {
  const router = useRouter();

  const {
    error,
    data,
  } = useQuery(FETCH, {
    ssr: false,
  });

  if (error) {
    throw error;
  }

  const banners = data?.banners.data ?? [];

  if (banners.length === 0) {
    return null;
  }

  const banner = banners[0] ?? {};
  const { excludedUrls = [], includedUrls = [] } = banner.attributes;
  const { asPath } = router;

  if (excludedUrls.length > 0) {
    const match = excludedUrls.find(({ url }) => asPath.startsWith(url));

    if (match) {
      return null;
    }
  }

  if (includedUrls.length > 0) {
    const match = includedUrls.find(({ url }) => asPath.startsWith(url));

    if (!match) {
      return null;
    }
  }

  return (
    <Box display="flex" justifyContent="center">
      <Box component="a" href={banner.attributes.url} sx={{ display: 'block' }}>
        {banner.attributes.fileDesktop && (
          <Box
            sx={{
              display: { md: 'block', xs: 'none' },
            }}
          >
            <img
              alt=""
              className="img-fluid"
              src={banner.attributes.fileDesktop.data.attributes.formats.large?.url
                    ?? banner.attributes.fileDesktop.data.attributes.url}
            />
          </Box>
        )}
        {banner.attributes.fileMobile && (
          <Box
            sx={{
              display: { md: 'none', xs: 'block' },
            }}
          >
            <img
              alt=""
              className="img-fluid"
              src={banner.attributes.fileMobile.data.attributes.formats.large?.url
                    ?? banner.attributes.fileMobile.data.attributes.url}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

const FETCH = gql`
  query GetBanners {
    banners {
      data {
        id
        attributes {
          url
          fileDesktop {
            data {
              attributes {
                url
                formats
              }
            }
          }
          fileMobile {
            data {
              attributes {
                url
                formats
              }
            }
          }
          excludedUrls {
            url
          }
          includedUrls {
            url
          }
        }
      }
    }
  }
`;
